<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
    <a-card :title="title">
      <a-table
        :columns="columns"
        :dataSource="datatable"
        size="small"
        :pagination="{
          defaultPageSize: 10,
          total: totaldatatable,
          showQuickJumper: true,
          showTotal: (total) => `Total ${total} items`,
          showSizeChanger: true,
          pageSizeOptions: ['10', '20', '30'],
        }"
      ></a-table>
      <!-- <ag-grid-vue
        style="height: 50vh; width: 100%;"
        :gridOptions="gridOptions"
        :class="
          settings.theme === 'dark'
            ? 'ag-theme-balham-dark mt-2'
            : 'ag-theme-balham mt-2'
        "
        :columnDefs="column"
        :rowData="datatable"
      ></ag-grid-vue> -->
    </a-card>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  created() {
    this.gridOptions = {}
    this.datenow = lou.datenow()
  },
  data() {
    return {
      title: '',
      gridOptions: null,
      columns: [],
      totaldatatable: 0,
      datatable: [],
    }
  },
  methods: {
    moment,
  },
}
</script>

<style></style>
