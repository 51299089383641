var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-12 col-lg-12"},[_c('a-card',{attrs:{"title":_vm.title}},[_c('a-table',{attrs:{"columns":_vm.columns,"dataSource":_vm.datatable,"size":"small","pagination":{
        defaultPageSize: 10,
        total: _vm.totaldatatable,
        showQuickJumper: true,
        showTotal: (total) => `Total ${total} items`,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30'],
      }}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }